import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import AdBanner from "../../components/ad-banner";

var tempAudio = null;

class View extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			text: "",
			errorMessage: ""
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}

		this.handleClick(null);
	}

	handleChange(event) {
		this.setState({ text: event.target.value, errorMessage: "" });
	}

	handleClick(event) {
		var changedText = document.getElementById('text').value;
		changedText = changedText.replace(/\n/g, "&#x2063;\n");
		changedText = changedText.replace(/ /g, "&#x2063; ");
		console.log(changedText);
		this.setState({ text: changedText }, () => {
			this.copyFromTextareaById("text");
		});
		if (event != null) {
			this.setState({
				errorMessage:
					"Awesome! Your text has been hopefully copied. Go forth and paste! 😜"
			});
		}
	}

	copyFromTextareaById = id => {
		var $tempInput = document.getElementById(id);
		$tempInput.select();
		document.execCommand("copy");
	};

	getDangerousHTML = str => {
		return { __html: str };
	};

	render() {
		return (
			<Layout>
				<SEO
					title="Spacer | Keep spaces and linebreaks in Instagram Captions, Tinder Bios etc."
					keywords={[
						`How to keep spaces linebreaks Instagram`,
						`keep linebreaks instagram`,
						`keep spaces tinder bio`
					]}
					description={
						"This tool helps you keep spaces and linebreaks in Instagram Captions, Tinder Bios etc. Showcase your text exactly the way you want to."
					}
				/>
				{/* <Navigation /> */}
				{/* <AdBanner></AdBanner> */}
				<Container>
					<Row style={{ display: "flex", justifyContent: "center" }}>
						<Col>
							<h3
								style={{
									textAlign: "center",
									fontWeight: "300"
								}}
							>
								<Link to="/products">
									{"⬅ Spacer | For Instagram Captions, Tinder Bios etc."}
								</Link>
							</h3>
						</Col>
					</Row>
					<div style={{ marginTop: 10 }} />
					<Row style={{ display: "flex", justifyContent: "center" }}>
						<Col>
							<h4
								style={{
									textAlign: "center",
									fontWeight: "300"
								}}
							>
								Write your Captions exactly how you want them to be displayed.
							</h4>
						</Col>
					</Row>
					<div style={{ marginTop: 10 }} />
				</Container>
				<Container
					style={{
						display: "flex",
						justifyContent: "center"
					}}
				>
					<div
						className="card-2"
						style={{
							padding: "1rem",
							backgroundColor: "white",
							width: "100%",
							maxWidth: 650
						}}
					>
						{this.state.errorMessage.length != "" ? (
							<Alert variant={"success"}>{this.state.errorMessage}</Alert>
						) : null}
						{/* <Form.Group>
							<Form.Control
								id="textarea"
								as="textarea"
								rows="5"
								value={this.state.text}
								onChange={this.handleChange}
								placeholder={"Text goes here."}
							/>
						</Form.Group> */}
						{/* <div class="form-group">
							<textarea
								rows="5"
								placeholder="Text goes here."
								id="textarea"
								class="form-control"
							/>
						</div> */}
						<div className="form-group"
							dangerouslySetInnerHTML={this.getDangerousHTML(`\
									<textarea id="text" class="form-control" rows="5" placeholder="Text goes here.">${
										this.state.text
									}</textarea> \
							`)}
						/>
						<div style={{ marginTop: 20 }} />
						<Row
							style={{
								display: "flex",
								justifyContent: "flex-end",
								marginRight: 20
							}}
						>
							{this.state.text != null ? (
								<small>Character count: {this.state.text.length}</small>
							) : null}
						</Row>
						<div style={{ marginTop: 20 }} />
						<Button
							id="convert-text-and-copy"
							variant="primary"
							block
							onClick={this.handleClick}
						>
							Generate and Copy Spaced Text
						</Button>
						<div style={{ marginTop: 20 }} />
						{/* <pre id="converted-text" /> */}
					</div>
				</Container>
				<div style={{ marginTop: 100 }} />
				<DonationFooter ad={false} />
			</Layout>
		);
	}
}

export default View;
